<template>
  <v-card
    :class="stylish ? 'rounded-lg pa-3' : share ? 'rounded-t-0 rounded-b-lg' : 'rounded-0'"
    class="d-flex align-center"
    :style="stylish ? '' : 'border-bottom: 1px solid #d2d2d2 !important; border-top: 1px solid #d2d2d2 !important;'"
    :color="stylish ? 'primary lighten-5' : ''"
    flat
    >
    <v-img
      class="grow"
      :class="stylish ? 'rounded-lg pa-3' : share ? 'rounded-t-0 rounded-b-lg' : 'rounded-0'"
      height="70"
      width="70"
      v-if="store.profile.picture"
      :src="url + store.profile.picture.url"
      ></v-img>
    <v-card
      class="grow d-flex align-center justify-center"
      :class="stylish ? 'rounded-lg pa-3' : share ? 'rounded-t-0 rounded-b-lg' : 'rounded-0'"
      flat
      height="70"
      width="70"
      color="grey"
      v-if="!store.profile.picture"
      >
      <v-icon
        size="50"
        color="white"
        >
        mdi-storefront-outline
      </v-icon>
    </v-card>
    <v-card-text
      class="d-flex flex-wrap align-center justify-space-between py-0 px-2 text--primary"
      >
      <div
        class="d-flex flex-column"
        >
        <div
          class="font-weight-medium body-1 text-capitalize store-card-box"
          >
          <v-icon style="margin-top: -2px" color="primary">mdi-storefront</v-icon> {{ store.name }}
        </div>
        <div
          class="store-card-box"
          >
          {{ store.information.fullAddress }}
        </div>
      </div>

      <div
        class="ml-auto d-flex flex-column mt-1"
        >
        <v-btn
          x-small
          text
          depressed
          class="d-flex align-center"
          color="primary"
          v-if="store.profile.slug != $route.params.slug"
          :to="{ name: 'profile', params: { slug: store.profile.slug } }"
          >
          <v-icon
            small
            class="mr-1"
            style="margin-top: -2px"
            >
            mdi-storefront
          </v-icon>
          <div>
            Ver Partner
          </div>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    store: {
      required: true,
      type: Object
    },
    share: {
      type: Boolean,
      required: false,
      default: false
    },
    stylish: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  computed: {
    ...mapGetters(['url']),
  },
}
</script>
